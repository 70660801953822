import React from "react";
import { Outlet, Route, Routes } from "react-router";
import { authRoutes, publicRoutes } from "./router.link";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import ThemeSettings from "../components/common/theme-settings/themeSettings";
import { useAppSelector } from "../redux/store";
import routePermissions from "./routePermissions";

const Announcements = React.lazy(() => import("./../screens/announcements"));
const ErrorPage = React.lazy(() => import("./../screens/general/ErrorPage"));
const CatalogPage = React.lazy(() => import("./../screens/other/CatalogPage"));

const ALLRoutes = () => {
  const user = useAppSelector((state) => state.user.data);
  const pageMap = useAppSelector((state) => state.user.pageMap);

  const HeaderLayout = () => (
    <>
      <Header />
      <Sidebar />
      <Outlet />
      <ThemeSettings />
    </>
  );
  const AuthPages = () => <Outlet />;

  function getPagePermissions(pageType: string) {
    const returnValue: any = {};
    const pagePermissions = routePermissions[pageType];

    for (const pageName in pagePermissions) {
      returnValue[pageName] = pageMap[pageName]?.permissions;
    }

    return returnValue;
  }

  return (
    <>
      <Routes>
        <Route path={"/urun-katalog"} element={<CatalogPage />} />
        {user ? (
          <Route path={"/"} element={<HeaderLayout />}>
            {!pageMap.systemcx && (
              <Route
                path={"/"}
                element={
                  <Announcements permissions={getPagePermissions("announce")} />
                }
              />
            )}

            {publicRoutes.map((route, idx) => {
              const canAccess = pageMap[route.pageType]?.canReach;

              return (
                <Route
                  path={route.path}
                  element={
                    canAccess ? (
                      <route.Content
                        permissions={getPagePermissions(route.pageType)}
                      />
                    ) : (
                      <ErrorPage />
                    )
                  }
                  key={route.path}
                />
              );
            })}
          </Route>
        ) : (
          <>
            <Route path={"/"} element={<AuthPages />}>
              {authRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={<route.Content />}
                  key={idx}
                />
              ))}
            </Route>
          </>
        )}
      </Routes>
    </>
  );
};

export default ALLRoutes;
