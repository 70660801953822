import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import ImageWithBasePath from "../imageWithBasePath";
import { useAppSelector } from "redux/store";
import { getSidebarConfigurations } from "./utils";

const Sidebar = () => {
  const Location = useLocation();

  const user = useAppSelector((state) => state.user.data);
  const permissions = useAppSelector((state) => state.user.pageMap);
  const [subOpen, setSubopen] = useState(window.location.pathname);
  const [sidebar, setSidebar] = useState<any[]>();
  const [subsidebar, setSubsidebar] = useState("");

  useEffect(() => {
    // const url = window.location.pathname.split("/");
    // if (window.location.pathname === "/") {
    // }
    const config = getSidebarConfigurations(permissions);
    setSidebar(config);
  }, []);

  const toggleSidebar = (title: any) => {
    if (title === subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
  };

  const toggleSubsidebar = (subitem: any) => {
    if (subitem === subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };

  if (!sidebar) return null;

  return (
    <>
      <div
        className="sidebar"
        id="sidebar"
        // onMouseEnter={toggle}
        // onMouseLeave={toggle}
      >
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li className="clinicdropdown">
                  <Link to="/profile">
                    <ImageWithBasePath
                      src={user.avatar || "assets/img/profiles/avatar-14.jpg"}
                      className="img-fluid"
                      alt="Profile"
                    />
                    <div className="user-names">
                      <h5>{user.name}</h5>
                      <h6>{user.role}</h6>
                    </div>
                  </Link>
                </li>
              </ul>

              <ul>
                {sidebar.map((mainLabel, index) => {
                  if (!mainLabel.permission) return null;

                  return (
                    <li className="clinicdropdown" key={index}>
                      <h6 className="submenu-hdr">{mainLabel?.label}</h6>

                      <ul>
                        {mainLabel?.submenuItems?.map((item: any, i: any) => {
                          if (!item.permission) return null;

                          let link_array: any = [];
                          if ("submenuItems" in item) {
                            item.submenuItems?.map((link: any) => {
                              link_array.push(link?.link);
                              if (link?.submenu && "submenuItems" in link) {
                                link.submenuItems?.map((item: any) => {
                                  link_array.push(item?.link);
                                });
                              }
                            });
                          }
                          item.links = link_array;

                          let isActive: boolean =
                            subOpen == item?.label ||
                            window.location.pathname === item.link;

                          const subActive =
                            isActive || item?.links?.includes(Location.pathname)
                              ? "active"
                              : "";

                          return (
                            <li
                              className="submenu"
                              key={"submenu-item-" + (item?.link || i + index)}
                            >
                              <Link
                                to={item?.link}
                                onClick={() => toggleSidebar(item?.label)}
                                className={`${
                                  isActive ? "subdrop" : ""
                                } ${subActive}
                            `}
                              >
                                {/* <Grid /> */}
                                <i className={item.icon}></i>
                                <span>{item?.label}</span>
                                <span
                                  className={item?.submenu ? "menu-arrow" : ""}
                                />
                              </Link>
                              <ul
                                style={{
                                  display: subActive ? "block" : "none",
                                }}
                              >
                                {item?.submenuItems?.map(
                                  (item: any, titleIndex: any) => {
                                    if (!item.permission) return null;

                                    return (
                                      <li
                                        className="submenu submenu-two"
                                        key={titleIndex}
                                      >
                                        {item.lebel}
                                        <Link
                                          to={item?.link}
                                          className={
                                            item?.submenuItems
                                              ?.map((link: any) => link?.link)
                                              .includes(Location.pathname) ||
                                            item?.link == Location.pathname
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            toggleSubsidebar(item?.label);
                                          }}
                                        >
                                          {item?.label}
                                          <span
                                            className={
                                              item?.submenu ? "menu-arrow" : ""
                                            }
                                          />
                                        </Link>
                                        <ul
                                          style={{
                                            display:
                                              subsidebar == item?.label
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          {item?.submenuItems?.map(
                                            (items: any, titleIndex: any) => (
                                              <li key={titleIndex}>
                                                {/* {item.lebel} */}
                                                <Link
                                                  to={items?.link}
                                                  className={`${
                                                    subsidebar == items?.label
                                                      ? "submenu-two subdrop"
                                                      : "submenu-two"
                                                  } ${
                                                    items?.submenuItems
                                                      ?.map(
                                                        (link: any) => link.link
                                                      )
                                                      .includes(
                                                        Location.pathname
                                                      ) ||
                                                    items?.link ==
                                                      Location.pathname
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  {items?.label}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default Sidebar;
