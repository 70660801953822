import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Input from "components/Input";
import handleError from "helpers/errorHandler";
import sendRequest from "helpers/service";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/store";
import { setDeleteModal, setLoader } from "redux/uiSlice";
import CenteredModal from "./CenteredModal";

export function DeleteModal() {
  const dispatch = useDispatch();
  const props = useAppSelector((state) => state.ui.deleteModal);

  const [optionValues, setOptionValues] = useState<any>({});
  const [validationInput, setValidationInput] = useState("");
  const [attempted, setAttempted] = useState(false);

  async function handleSubmit() {
    if (!props) return;

    try {
      setAttempted(true);
      if (props.validation && validationText !== validationText) return;

      setValidationInput("");
      setAttempted(false);
      dispatch(setLoader(true));
      const res: any = await sendRequest.delete({
        url: props.apiUrl,
        data: optionValues,
      });

      if (props.successText) {
        toast(props.successText, {
          type: "success",
          theme: "colored",
        });
      }

      dispatch(setDeleteModal(false));
      dispatch(setLoader(false));

      if (props.onSuccess) props.onSuccess(res.data);
    } catch (error) {
      handleError(error);
    }
  }

  function onClose() {
    dispatch(setDeleteModal(false));
  }

  if (!props) return null;
  const validationText: string = props.validationText || "kalıcı olarak sil";

  return (
    <CenteredModal title={props.title} onClose={onClose}>
      <span
        style={{ fontSize: 14, color: "black" }}
        dangerouslySetInnerHTML={{ __html: props.message }}
      />

      {props.warnings?.map((warning, index) => {
        return (
          <span
            key={"delete-modal-warning-" + index}
            style={{
              marginTop: "2%",
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              color: "darkorange",
            }}
          >
            <i
              className="fa fa-warning"
              style={{ fontSize: "15px", marginRight: "5px" }}
            />
            <span dangerouslySetInnerHTML={{ __html: warning }} />
          </span>
        );
      })}

      {props.options && (
        <>
          <hr style={{ margin: "2% 0" }} />
          <span color={"black"}>
            {props.optionLabel ||
              "These informations can be deleted with the record. Please select options to delete that related with the record"}
          </span>
          {props.options?.map((option, index) => {
            if (!option.permission) return false;
            return (
              <div className="col-xs-6 my-2" key={"option-" + option.keyword}>
                <Checkbox
                  checked={optionValues[option.keyword] ? true : false}
                  onChange={(value) => {
                    setOptionValues((values: any) => {
                      values[option.keyword] = value;
                      return values;
                    });
                  }}
                />
                <span
                  style={{ fontSize: 14 }}
                  dangerouslySetInnerHTML={{ __html: option.text }}
                />
                <span
                  style={{ fontSize: 13, color: "rgb(255, 100, 100)" }}
                  dangerouslySetInnerHTML={{ __html: option.warning }}
                />
                {props.options && props.options.length - 1 !== index && (
                  <hr style={{ marginTop: "2%" }} />
                )}
              </div>
            );
          })}
        </>
      )}

      {props.validation && (
        <>
          <hr style={{ margin: "4% 0" }} />
          <div style={{ marginBottom: "1%", fontSize: 14 }}>
            Kaydı silmek için aşağıya "<b>{validationText}</b>" yazınız
          </div>
          <Input
            value={validationInput}
            onChange={setValidationInput}
            warn={attempted && props.validationText !== validationText}
            placeholder={validationText}
            warning="Lütfen validasyon metnini düzgün girin"
          />
        </>
      )}

      <div className="row mt-4">
        <div className="col-md-12 d-flex justify-content-end">
          <Button
            theme="light"
            style={{ marginRight: "10px" }}
            text={"Kapat"}
            onClick={onClose}
            // disabled={props.validation && validationInput !== validationText}
            autoFocus
          />
          <Button
            style={{ width: "40%" }}
            theme="danger"
            text={props.actionButtonText || "Sil"}
            onClick={handleSubmit}
            disabled={props.validation && validationInput !== validationText}
          />
        </div>
      </div>
    </CenteredModal>
  );
}
