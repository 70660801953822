import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UIProps {
  deleteModal: false | DeleteModalProps;
  dialogModal: false | DialogModalProps;
  imageModal: string | false;
  miniSideBar: boolean;
  themeSettings: boolean;
  mobileSidebar: boolean;
  headerCollapse: boolean;
  expandMenu: boolean;
  loader: boolean;
}

interface DeleteModalProps {
  title: string;
  message: string;
  validation?: boolean;
  validationText?: string;
  actionButtonText?: string;
  warnings: string[];
  optionLabel?: string;
  apiUrl: string;
  successText?: string;
  onSuccess?: (data: any) => void;
  options?: Array<{
    keyword: string; //boolean değer için
    text: string;
    warning: string;
    permission: boolean; //Eğer işlem yetkisi yoksa silerken o opsiyon gözükmeyecektir.
  }>;
}

interface DialogModalProps {
  title: string;
  message: string;
  onClose?: () => void;
  input?: {
    label: string;
    mandatory?: boolean;
    onValidate?: (input: any) => boolean;
    warningText?: string;
  };
  button?: {
    text: string;
    onClick: (value: string) => void;
  };
  cancel?: {
    text: string;
    onClick?: () => void;
  };
}

const initialState: UIProps = {
  deleteModal: false,
  imageModal: false,
  dialogModal: false,
  loader: false,
  miniSideBar: localStorage.getItem("miniSideBar") === "on",
  themeSettings: false,
  mobileSidebar: false,
  headerCollapse: false,
  expandMenu: false,
};
const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setImageModal: (state, { payload }) => {
      state.imageModal = payload;
    },
    setDeleteModal: (
      state,
      { payload }: PayloadAction<DeleteModalProps | false>
    ) => {
      state.deleteModal = payload;
      return state;
    },
    setDialogModal: (
      state,
      { payload }: PayloadAction<DialogModalProps | false>
    ) => {
      state.dialogModal = payload;
      return state;
    },
    setLoader: (state, { payload }) => {
      state.loader = payload;
    },
    setMiniSidebar: (state, { payload }) => {
      state.miniSideBar = payload;
      localStorage.setItem("miniSideBar", payload ? "on" : "off");
    },
    setThemeSettings: (state, { payload }) => {
      state.themeSettings = payload;
    },
    setMobileSidebar: (state, { payload }) => {
      state.mobileSidebar = payload;
    },
    setHeaderCollapse: (state, { payload }) => {
      state.headerCollapse = payload;
    },
    setExpandMenu: (state, { payload }) => {
      state.headerCollapse = payload;
    },
  },
});

export const {
  setDeleteModal,
  setDialogModal,
  setLoader,
  setImageModal,
  setMiniSidebar,
  setThemeSettings,
  setMobileSidebar,
  setHeaderCollapse,
  setExpandMenu,
} = uiSlice.actions;

export default uiSlice.reducer;
