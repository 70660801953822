import { combineReducers, configureStore } from "@reduxjs/toolkit";
import uiSlice from "./uiSlice";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import userSlice from "./userSlice";
import settingsSlice from "./settingsSlice";

const rootReducer = combineReducers({
  ui: uiSlice,
  user: userSlice,
  settings: settingsSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
