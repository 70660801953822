import { useState } from "react";

interface InputProps extends Partial<HTMLInputElement> {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  name?: string;
  warn?: boolean;
  warning?: string;
  label?: string;
  mandatory?: boolean;
  type?:
    | "text"
    | "tel"
    | "password"
    | "email"
    | "phone"
    | "textarea"
    | "number";
  leftIcon?: string;
  rightIcon?: string;
  optins?: {
    //TODO: ihtiyaç olursa burayı düzenle
    text: string;
  };
}

export default function Input(props: InputProps) {
  const [passwordState, setPasswordState] = useState(false);
  return (
    <div className="form-wrap">
      <div className="d-flex justify-content-between align-items-center">
        {props.label && (
          <label
            className="col-form-label mb-0"
            style={{ fontSize: 13, color: "rgb(70, 70, 70)" }}
          >
            {props.label}
            {props.mandatory && <span className="text-danger">*</span>}
          </label>
        )}
        {props.optins && (
          <div className="status-toggle small-toggle-btn d-flex align-items-center">
            <span className="me-2 label-text">{props.optins.text}</span>
            <input
              type="checkbox"
              id="user1"
              className="check"
              defaultChecked={true}
            />
            <label htmlFor="user1" className="checktoggle" />
          </div>
        )}
      </div>

      {props.type === "password" && (
        <div
          className="icon-form-end"
          style={{ top: "21px", fontSize: "16px" }}
        >
          <span
            className={"form-icon"}
            onClick={() => setPasswordState(!passwordState)}
          >
            <i
              style={{ fontSize: "16px" }}
              className={passwordState ? "ti ti-eye" : "ti ti-eye-off"}
            />
          </span>
        </div>
      )}
      <div
        className={
          "form-wrap mb-0 " +
          (props.leftIcon || props.rightIcon ? "icon-form" : "")
        }
      >
        {props.leftIcon && (
          <span className="form-icon">
            <i className={props.leftIcon}></i>
          </span>
        )}
        {props.type === "textarea" ? (
          <textarea
            className="form-control"
            rows={5}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            disabled={props.disabled}
          />
        ) : (
          <input
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            placeholder={props.placeholder}
            className={"form-control"}
            name={props.name}
            type={passwordState ? "text" : props.type || "text"}
            disabled={props.disabled}
            style={{ color: "black" }}
            min={props.min}
          />
        )}
        {props.rightIcon && (
          <span className="form-icon">
            <i className={props.rightIcon}></i>
          </span>
        )}
      </div>
      {props.warn && (
        <div className="mt-1 text-danger font-bold" style={{ fontSize: 12 }}>
          {props.warning || "Bu alan zorunludur."}
        </div>
      )}
    </div>
  );
}
