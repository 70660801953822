import { toast } from "react-toastify";
import store from "./../redux/store";
import { setLoader } from "redux/uiSlice";
import { toastMessage } from "./mixins";
import { logout } from "redux/userSlice";

export default function handleError(error: any) {
  let message =
    error?.response?.data || error?.message || "Unexpected Error Occured.";

  if (typeof error === "string") {
    message = error;
  } else if (error?.response?.status === 403) {
    message = "You don't have access to this resource!";
  } else if (error?.response?.status === 401) {
    console.log("@logout erorr", error, error.config);
    message = "Your session has timed out. Please login again.";
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("p");
    store.dispatch(logout());
    // window.location.pathname = "/login";
  } else if (typeof message == "object" && message.exception) {
    message = message.exception;
  } else if (message.validation_errors) {
    let validationMessage = "";
    for (const key in message.validation_errors) {
      const value = message.validation_errors[key];
      validationMessage += "⦿ " + value[0] + "<br />";
    }
    message = validationMessage;
  } else if (message.data) {
    message = message.data;
  }

  console.log("@burada", message);

  store.dispatch(setLoader(false));
  toastMessage(message, "error");
}
